@font-face {
    font-family: 'Sen';

    font-weight: 400;
    font-style: normal;
    src: local('Sen'), url('../assets/fonts/Sen/Sen-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Sen';

    font-weight: 500;
    font-style: normal;
    src: local('Sen'), url('../assets/fonts/Sen/Sen-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Sen';

    font-weight: 600;
    font-style: normal;
    src: local('Sen'), url('../assets/fonts/Sen/Sen-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Sen';

    font-weight: 700;
    font-style: normal;
    src: local('Sen'), url('../assets/fonts/Sen/Sen-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Sen';

    font-weight: 800;
    font-style: normal;
    src: local('Sen'), url('../assets/fonts/Sen/Sen-SemiBold.ttf') format('truetype');
}
