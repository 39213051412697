// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include reset css and custom variables
@use './scss/reset';
@use './scss/variables';
@import './scss/fonts.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$template-frontend-angular-primary: mat.define-palette(mat.$indigo-palette);
$template-frontend-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$template-frontend-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$template-frontend-angular-theme: mat.define-light-theme(
    (
      color: (
        primary: $template-frontend-angular-primary,
        accent: $template-frontend-angular-accent,
        warn: $template-frontend-angular-warn
      )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($template-frontend-angular-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'photoswipe/dist/photoswipe.css';
.pswp__img {
  cursor: pointer !important;
}

html,
body {
  height: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: Sen, 'Helvetica Neue', sans-serif;
  font-weight: 400;

  .mat-mdc-dialog-container .mdc-dialog__content {
    font-family: Sen, 'Helvetica Neue', sans-serif;
    letter-spacing: 0;
  }
}

.btn-primary {
  padding: 8px 20px;
  border-radius: 24px;
  color: var(--color-white);
  background-color: var(--color-brand1);
  cursor: pointer;
  max-height: 40px;

  & mat-icon {
    color: var(--color-white);
  }
}

.btn-secondary {
  padding: 8px 20px;
  border-radius: 24px;
  color: var(--color-brand1);
  border: 1px solid var(--color-brand1);
  align-items: center;
  cursor: pointer;
  max-height: 40px;
}

.btn-gradient-1 {
  padding: 8px 20px;
  border-radius: 24px;
  color: var(--color-white);
  background-color: var(--color-step2);
  cursor: pointer;
  max-height: 40px;
}

.btn-gradient-2 {
  padding: 8px 20px;
  border-radius: 24px;
  color: var(--color-white);
  background-color: var(--color-step4);
  cursor: pointer;
  max-height: 40px;
}

.bg-800 {
  background: var(--gradient---gradient-800);
}

.\!bg-800 {
  background: var(--gradient---gradient-800) !important;
}

.bg-light-gray-200 {
  background: var(--light-grey--200);
}

.linear-gradient-primary {
  background: linear-gradient(
      90.25deg,
      var(--color-brand1) 0%,
      #23b07b 50%,
      var(--color-brand2) 100%
  );
}

.linear-gradient-secondary {
  background: linear-gradient(134deg, #45aa3d 0%, #23b07b 49%, #03673e 100%);
}

/* Success syling */
button.mat-success {
  color: #f0fff3;
  background-color: #bee8bb;
}

button.mat-success:hover {
  background-color: #f0fff3;
  color: #155724;
}

mat-form-field {
  color: var(--color-black) !important;
  flex-direction: row !important;
  height: 80px;
  width: 100%;

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    display: flex !important;
    align-items: center !important;
    padding: 0px !important;
    min-height: 48px !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 23px !important;
  }

  input,
  mat-label,
  textarea {
    color: var(--color-black) !important;
  }

  input::placeholder {
    color: var(--color-black) !important;
  }

  .mat-mdc-text-field-wrapper {
    border-radius: 4px !important;
    background-color: var(--color-white) !important;
    height: 48px !important;
  }

  .mdc-floating-label--float-above {
    top: 65% !important;
  }

  .mdc-notched-outline,
  .mdc-notched-outline__notch {
    border-left: none;
    border-right: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  mat-select-content {
    width: 2000px;
    background-color: red;
    font-size: 10px;
  }

  mat-select {
    .mat-mdc-select-value {
      color: var(--color-black) !important;
    }

    svg {
      stroke: var(--color-black) !important;
    }
  }

  mat-error {
    position: absolute !important;
    top: 110% !important;
    left: 0 !important;
    text-align: start !important;
  }

  textarea {
    margin-top: 16px !important;
    height: 102px !important;
  }

  &:has(textarea) {
    height: 160px;

    .mat-mdc-text-field-wrapper {
      height: 128px !important;
    }
  }
}

//mat-chip
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary:before {
  border: none !important;
}

//mat-checkbox
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--color-brand1);
  --mdc-checkbox-selected-hover-icon-color: var(--color-brand1);
  --mdc-checkbox-selected-icon-color: var(--color-brand1);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-brand1);
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-brand1);
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  background-color: #0f5b1d;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: var(--color-brand1);
}

.mat-mdc-checkbox.mat-accent
.mdc-checkbox--selected
~ .mat-mdc-checkbox-ripple
.mat-ripple-element {
  background-color: var(--primary-opacity---primary-010) !important;
}

//mat-radio-button
.mat-mdc-radio-button.mat-primary,
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-brand1);
  --mdc-radio-selected-hover-icon-color: var(--color-brand1);
  --mdc-radio-selected-icon-color: var(--color-brand1);
  --mdc-radio-selected-pressed-icon-color: var(--color-brand1);
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: var(--color-brand1);
}

//mat-slider
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: var(--color-brand1);
  --mdc-slider-focus-handle-color: var(--color-brand1);
  --mdc-slider-hover-handle-color: var(--color-brand1);
  --mdc-slider-active-track-color: var(--color-brand1);
  --mdc-slider-inactive-track-color: var(--color-brand1);
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mat-mdc-slider-ripple-color: var(--color-brand1);
  --mat-mdc-slider-hover-ripple-color: rgba(69, 170, 62, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(69, 170, 62, 0.2);
}

/* mat-form-field -- Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
.mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
.mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
.mdc-notched-outline__trailing {
  border-color: var(--color-brand1) !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
.mdc-notched-outline__notch {
  border-left: 1px solid transparent !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined
.mdc-notched-outline--upgraded
.mdc-floating-label--float-above {
  color: var(--color-brand1);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #f44336;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--color-brand1);
}

/* mat-form-field -- to change selected item color in the select list */
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--color-brand1) !important;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--color-brand1) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-brand1) !important;
}

/* mat-datepicker */
.mat-datepicker-toggle-active {
  color: var(--color-brand1) !important;
}

.mat-calendar-body-selected {
  background-color: var(--color-brand1) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
    ) {
  background-color: var(--primary---primary-200) !important;
}

.cdk-keyboard-focused
.mat-calendar-body-active
> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
    ),
.cdk-program-focused
.mat-calendar-body-active
> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
    ) {
  background-color: var(--primary---primary-200) !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--color-brand1) !important;
}

.mat-calendar-body-in-range::before {
  background-color: var(--primary---primary-100) !important;
}

/* mat-tab */
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: var(--color-brand1) !important;
}

.mat-mdc-tooltip {
  white-space: pre-line !important;

  .mdc-tooltip__surface {
    max-width: 380px;
  }
}

.mat-mdc-select-panel {
  border-radius: 12px !important;
  padding: 0 !important;
  margin-top: 10px !important;

  mat-option {
    background-color: var(--color-white) !important;

    &:hover {
      background-color: var(--color-gray2) !important;

      span {
        color: var(--color-black) !important;
      }
    }

    span {
      color: var(--color-black) !important;
    }

    &.mat-mdc-option.mat-mdc-option-active {
      background-color: var(--color-gray2) !important;
      color: var(--color-black) !important;

      span {
        color: var(--color-brand2) !important;
      }
    }
  }
}

// delete material button first active color, tab selector
button.mat-mdc-button {
  --mat-mdc-button-persistent-ripple-color: transparent;
}

.mat-raised-button-primary-green {
  &.mat-mdc-raised-button:not(:disabled) {
    background-color: var(--color-brand1);
    color: #fff;
  }

  &.mat-mdc-raised-button.brand2:not(:disabled) {
    background-color: var(--color-brand2);
  }
}

.mat-mdc-select-placeholder {
  color: white !important;
}

//Badges
.primary-light.mat-mdc-standard-chip {
  background-color: #e6f0e6 !important;
  --mdc-chip-label-text-color: #0f5b1d !important;
  font-weight: 500;
}

.primary-medium.mat-mdc-standard-chip {
  background-color: #45aa3d !important;
  --mdc-chip-label-text-color: #ffffff !important;
  font-weight: 500;
}

.primary-dark.mat-mdc-standard-chip {
  background-color: #2c6c27 !important;
  --mdc-chip-label-text-color: #ffffff !important;
  font-weight: 500;
}

.trial-light.mat-mdc-standard-chip {
  background-color: rgba(69, 170, 62, 0.1) !important;
  --mdc-chip-label-text-color: #0f5b1d !important;
  font-weight: 500;
}

.premium-medium.mat-mdc-standard-chip {
  background-color: rgba(69, 170, 62, 0.3) !important;
  --mdc-chip-label-text-color: #0f5b1d !important;
  font-weight: 500;
}

.unlimited-dark.mat-mdc-standard-chip {
  background-color: rgba(69, 170, 62, 0.5) !important;
  --mdc-chip-label-text-color: #0f5b1d !important;
  font-weight: 500;
}

.secondary-light.mat-mdc-standard-chip {
  background-color: var(--color-draft-phase-status) !important;
  --mdc-chip-label-text-color: #166f4e !important;
  font-weight: 500;
}

.secondary-dark.mat-mdc-standard-chip {
  background-color: var(--color-trial-draft-phase-status) !important;
  --mdc-chip-label-text-color: #ffffff !important;
  font-weight: 500;
}

//Admin uniformat/Omniclass
mat-form-field.select-identifier {
  height: 100% !important;

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border: 0 !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border: 0 !important;
  }
}


.flex-50 {
  flex: 1 1 50%;
}

.flex-40 {
  flex: 1 1 40%;
}


.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primary-500);
  --mdc-linear-progress-track-color: var(--primary-opacity---primary-030);

  .mdc-linear-progress__buffer-bar {
    background-color: var(--mdc-linear-progress-track-color);
    border-radius: 9999px;
  }

  .mdc-linear-progress__bar-inner {
    border-radius: 9999px;
  }
}


.mdc-dialog__surface {
  overflow-y: hidden !important;
  position: unset !important;
}

//.mat-dialog-container {
//  overflow: unset;
//  display: flex;
//  flex-direction: column;
//
//  dialog-content-example-dialog {
//    display: flex;
//    flex-direction: column;
//    min-height: 1px;
//  }
//}

.flex-33 {
  flex: 0 1 33.33333333333%;
}

.flex-1\/3 {
  flex: 1 0 30%;
  max-width: 33%;
}

.flex-1\/4 {
  flex: 1 0 22%;
  max-width: 25%;
}

.flex-1\/2 {
  flex: 1 0 45%;
  max-width: 50%;
}

.md\:flex-1\/2 {
  @media (max-width: 768px) {
    flex: 1 0 45%;
    max-width: 50%;
  }
}

.flex-50 {
  flex: 0 1 50%;
}


.flex-100 {
  flex: 1 1 100%;
}

.md\:flex-100 {
  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
}


.bg-brand1-5p {
  background: var(--primary-500-5) !important;
  border: 2px solid var(--primary-500) !important;
}

.selectable-item {
  &:hover {
    box-shadow: 0 1px 12px 0 #00000026;
  }

  &.selected {
    background: var(--primary-500-5) !important;
  }
}

.recommended-border-r12, .recommended-border-r8 {
  box-sizing: border-box;
  border: none !important;
  padding: 2px !important;

  &.selected {
    background: var(--primary-500-5) !important;
    border: 2px solid var(--primary-500) !important;
    padding: 0 !important;

    &.recommended-border-and-bg {
      background: linear-gradient(90deg, rgba(162, 224, 15, 0.3) 0%, rgba(69, 170, 61, 0.3) 50%, rgba(190, 225, 31, 0.3) 100%) !important;
      //border: 2px solid rgba(162, 224, 15, 0.3) !important;
    }
  }
}

.recommended-bg {
  background: linear-gradient(90deg, rgba(162, 224, 15, 0.3) 0%, rgba(69, 170, 61, 0.3) 50%, rgba(190, 225, 31, 0.3) 100%);
}

.recommended-border-and-bg {
  background: linear-gradient(90deg, rgba(162, 224, 15, 0.3) 0%, rgba(69, 170, 61, 0.3) 50%, rgba(190, 225, 31, 0.3) 100%);
  border: 2px solid rgba(162, 224, 15, 0.3);
}

// https://unused-css.com/tools/border-gradient-generator?p=12Ymc9dCNmZmZmZmYsMiwxMiwxMCxweCwxO2woOTBkZWcsI0EyRTAwRiAwJSwjNDVhYTNkIDUwJSwjYmVlMTFmIDEwMCUp
.recommended-border-r12 {
  border-radius: 12px;
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 10px, transparent 10px) 0% 0%/12px 12px no-repeat,
  radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 10px, transparent 10px) 100% 0%/12px 12px no-repeat,
  radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 10px, transparent 10px) 0% 100%/12px 12px no-repeat,
  radial-gradient(circle at 0 0, #ffffff 0, #ffffff 10px, transparent 10px) 100% 100%/12px 12px no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 4px) calc(100% - 24px) no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 24px) calc(100% - 4px) no-repeat,
  linear-gradient(90deg, #A2E00F 0%, #45aa3d 50%, #bee11f 100%);

  // does not work with radius
  //border: 2px solid;
  //border-image: linear-gradient(90deg, #A2E00F 0%, #45AA3D 50%, #BEE11F 100%) 1 !important;

  //border-image-source: linear-gradient(90deg, #A2E00F 0%, #45AA3D 50%, #BEE11F 100%) !important;
  //border-image-source: linear-gradient(90deg, #A2E00F 0%, #45AA3D 50%, #BEE11F 100%) !important;
  //border-image-slice: 1 !important;
}

.recommended-border-r8 {
  border-radius: 8px;
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 6px, transparent 6px) 0% 0%/8px 8px no-repeat,
  radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 6px, transparent 6px) 100% 0%/8px 8px no-repeat,
  radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 6px, transparent 6px) 0% 100%/8px 8px no-repeat,
  radial-gradient(circle at 0 0, #ffffff 0, #ffffff 6px, transparent 6px) 100% 100%/8px 8px no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 4px) calc(100% - 16px) no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 4px) no-repeat,
  linear-gradient(90deg, #a2e00f 0%, #45aa3d 50%, #bee11f 100%);
}


.mat-mdc-fab, .mat-mdc-mini-fab {
  &.no-shadow {
    box-shadow: none;
  }
}

.border-gray-200 {
  border-color: #eeeeee;
}

.border-error {
  border-color: var(--basic-colors---danger, #f44336);
}

.horizon-divider {

  display: flex;
  flex-direction: row;

  &:before, &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #b2b2b2;
    margin: auto;
  }

  &:before {
    margin-right: 1rem
  }

  &:after {
    margin-left: 1rem
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.z-1000 {
  z-index: 1000;
}


.control-error {
  border-width: 2px;
  border-color: var(--basic-colors---danger, #f44336);
  background-color: var(--paprika---paprika-50, #F7F3F2);
}


mat-spinner.brand1-theme {
  ::ng-deep circle {
    stroke: var(--color-brand1);
  }

  circle {
    stroke: var(--color-brand1);
  }
}

mat-spinner.white-theme {
  ::ng-deep circle {
    stroke: #fff;
  }

  circle {
    stroke: #fff;
  }
}


.google-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #747775;
  border-radius: 4px;
  color: #1F1F1F;
  gap: 10px;
  cursor: pointer;

  mat-icon {
    height: 18px !important;
    width: 18px !important;
  }
}

.facebook-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1877F2;
  border-radius: 4px;
  color: #1877F2;
  gap: 10px;
  cursor: pointer;
}
