/*	CSS variables
------------------------------------------------------- */
:root {
  --primary: #45aa3d;
  --primary-500: #45aa3d;
  --primary-500-5: #45AA3D0D;

  --primary-line: var(--light-theme---light--outline);

  --light-theme---dark--text: rgba(18, 35, 48, 1);
  --light-theme---faded-text: rgba(100, 110, 113, 1);
  --light-theme---medium-text: rgba(141, 153, 165, 1);
  --light-theme---light-text: rgba(199, 204, 209, 1);
  --light-theme---light--background: rgba(242, 243, 244, 1);
  --light-theme---light--container: rgba(255, 255, 255, 1);
  --light-theme---light--outline: rgba(229, 231, 233, 1);
  --light-theme---dark-icon: rgba(18, 35, 48, 1);
  --basic-colors---primary: rgba(69, 170, 61, 1);
  --basic-colors---primary-01: rgba(69, 170, 62, 0.10000000149011612);
  --basic-colors---primary-02: rgba(69, 170, 62, 0.20000000298023224);
  --basic-colors---primary-03: rgba(69, 170, 62, 0.30000001192092896);
  --basic-colors---primary-darker: rgba(31, 120, 72, 1);
  --basic-colors---primary--highlight: rgba(0, 226, 100, 1);
  --basic-colors---primary--focused: rgba(0, 195, 60, 1);
  --basic-colors---danger: rgba(240, 68, 68, 1);
  --primary-opacity---primary-005: rgba(69, 170, 61, 0.05000000074505806);
  --primary-opacity---primary-010: rgba(69, 170, 61, 0.10000000149011612);
  --primary-opacity---primary-020: rgba(69, 170, 61, 0.20000000298023224);
  --primary-opacity---primary-030: rgba(69, 170, 61, 0.30000001192092896);
  --primary-opacity---primary-040: rgba(69, 170, 61, 0.4000000059604645);
  --primary-opacity---primary-050: rgba(69, 170, 61, 0.5);
  --primary-opacity---primary-060: rgba(69, 170, 61, 0.6000000238418579);
  --primary-opacity---primary-070: rgba(69, 170, 61, 0.699999988079071);
  --primary-opacity---primary-080: rgba(69, 170, 61, 0.800000011920929);
  --primary-opacity---primary-090: rgba(69, 170, 61, 0.8999999761581421);
  --primary---primary-50: rgba(243, 248, 243, 1);
  --primary---primary-100: rgba(230, 240, 230, 1);
  --primary---primary-200: rgba(202, 225, 201, 1);
  --primary---primary-300: rgba(170, 208, 168, 1);
  --primary---primary-400: rgba(130, 190, 126, 1);
  --primary---primary-500: rgba(69, 170, 61, 1);
  --primary---primary-600: rgba(62, 152, 55, 1);
  --primary---primary-700: rgba(53, 132, 47, 1);
  --primary---primary-800: rgba(44, 108, 39, 1);
  --primary---primary-900: rgba(31, 76, 27, 1);
  --gradient---gradient-100: rgba(230, 240, 230, 1);
  --gradient---gradient-200: rgba(212, 230, 211, 1);
  --gradient---gradient-300: rgba(157, 202, 155, 1);
  --gradient---gradient-400: rgba(69, 170, 61, 1);
  --gradient---gradient-500: rgba(60, 172, 87, 1);
  --gradient---gradient-600: rgba(49, 174, 106, 1);
  --gradient---gradient-700: rgba(35, 176, 123, 1);
  --gradient---gradient-800: rgba(29, 156, 107, 1);
  --gradient---gradient-900: rgba(20, 132, 87, 1);
  --gradient---gradient-1000: rgba(3, 103, 62, 1);
  --gradient---gradient-1100: rgba(2, 84, 51, 1);
  --gradient---gradient-1200: rgba(2, 59, 36, 1);
  --teal---teal-50: rgba(242, 248, 245, 1);
  --teal---teal-100: rgba(229, 241, 235, 1);
  --teal---teal-200: rgba(199, 227, 212, 1);
  --teal---teal-300: rgba(164, 211, 187, 1);
  --teal---teal-400: rgba(118, 194, 158, 1);
  --teal---teal-500: rgba(35, 176, 123, 1);
  --teal---teal-600: rgba(31, 157, 110, 1);
  --teal---teal-700: rgba(27, 136, 95, 1);
  --teal---teal-800: rgba(22, 111, 78, 1);
  --teal---teal-900: rgba(16, 79, 55, 1);
  --secondary---secondary-50: rgba(228, 233, 230, 1);
  --secondary---secondary-100: rgba(228, 233, 230, 1);
  --secondary---secondary-200: rgba(198, 208, 201, 1);
  --secondary---secondary-300: rgba(161, 180, 168, 1);
  --secondary---secondary-400: rgba(114, 147, 127, 1);
  --secondary---secondary-500: rgba(3, 103, 62, 1);
  --secondary---secondary-600: rgba(3, 92, 55, 1);
  --secondary---secondary-700: rgba(2, 80, 48, 1);
  --secondary---secondary-800: rgba(2, 65, 39, 1);
  --secondary---secondary-900: rgba(1, 46, 28, 1);
  --greys---white: rgba(255, 255, 255, 1);
  --greys---grey-50: rgba(250, 250, 250, 1);
  --greys---grey-100: rgba(245, 245, 245, 1);
  --greys---grey-200: rgba(238, 238, 238, 1);
  --greys---grey-300: rgba(224, 224, 224, 1);
  --greys---grey-400: rgba(188, 188, 188, 1);
  --greys---grey-500: rgba(158, 158, 158, 1);
  --greys---grey-600: rgba(117, 117, 117, 1);
  --greys---grey-700: rgba(97, 97, 97, 1);
  --greys---grey-800: rgba(66, 66, 66, 1);
  --greys---grey-900: rgba(33, 33, 33, 1);
  --greys---black: rgba(0, 0, 0, 1);
  --paprika---paprika-50: rgba(247, 243, 242, 1);
  --paprika---paprika-100: rgba(240, 230, 229, 1);
  --paprika---paprika-200: rgba(223, 202, 199, 1);
  --paprika---paprika-300: rgba(205, 170, 165, 1);
  --paprika---paprika-400: rgba(186, 129, 121, 1);
  --paprika---paprika-500: rgba(164, 68, 44, 1);
  --paprika---paprika-600: rgba(147, 61, 39, 1);
  --paprika---paprika-700: rgba(127, 53, 34, 1);
  --paprika---paprika-800: rgba(104, 43, 28, 1);
  --paprika---paprika-900: rgba(73, 30, 20, 1);
  --blue-grey---blue-grey-50: rgba(242, 243, 244, 1);
  --blue-grey---blue-grey-100: rgba(229, 231, 233, 1);
  --blue-grey---blue-grey-200: rgba(199, 204, 209, 1);
  --blue-grey---blue-grey-300: rgba(164, 172, 181, 1);
  --blue-grey---blue-grey-400: rgba(120, 134, 149, 1);
  --blue-grey---blue-grey-500: rgba(40, 79, 107, 1);
  --blue-grey---blue-grey-600: rgba(36, 71, 96, 1);
  --blue-grey---blue-grey-700: rgba(31, 61, 83, 1);
  --blue-grey---blue-grey-800: rgba(25, 50, 68, 1);
  --blue-grey---blue-grey-900: rgba(18, 35, 48, 1);
  --violet---violet-50: rgba(243, 242, 244, 1);
  --violet---violet-100: rgba(229, 229, 233, 1);
  --violet---violet-200: rgba(200, 199, 209, 1);
  --violet---violet-300: rgba(167, 164, 181, 1);
  --violet---violet-400: rgba(124, 120, 149, 1);
  --violet---violet-500: rgba(54, 40, 107, 1);
  --violet---violet-600: rgba(48, 36, 96, 1);
  --violet---violet-700: rgba(42, 31, 83, 1);
  --violet---violet-800: rgba(34, 25, 68, 1);
  --violet---violet-900: rgba(24, 18, 48, 1);
  --blacklist--50: rgba(242, 242, 243, 1);
  --blacklist--100: rgba(228, 228, 229, 1);
  --blacklist--200: rgba(198, 198, 201, 1);
  --blacklist--300: rgba(163, 162, 167, 1);
  --blacklist--400: rgba(117, 116, 125, 1);
  --blacklist--500: rgba(28, 21, 56, 1);
  --blacklist--600: rgba(25, 19, 50, 1);
  --blacklist--700: rgba(22, 16, 43, 1);
  --blacklist--800: rgba(18, 13, 35, 1);
  --blacklist--900: rgba(13, 9, 25, 1);
  --dark-grey--50: rgba(244, 244, 245, 1);
  --dark-grey--100: rgba(232, 233, 234, 1);
  --dark-grey--200: rgba(207, 209, 210, 1);
  --dark-grey--300: rgba(179, 182, 183, 1);
  --dark-grey--400: rgba(145, 151, 152, 1);
  --dark-grey--500: rgba(100, 110, 113, 1);
  --dark-grey--600: rgba(89, 98, 101, 1);
  --dark-grey--700: rgba(77, 85, 88, 1);
  --dark-grey--800: rgba(63, 70, 71, 1);
  --dark-grey--900: rgba(45, 49, 51, 1);
  --light-grey--50: rgba(253, 253, 253, 1);
  --light-grey--100: rgba(252, 252, 252, 1);
  --light-grey--200: rgba(248, 248, 248, 1);
  --light-grey--300: rgba(244, 244, 245, 1);
  --light-grey--400: rgba(241, 241, 241, 1);
  --light-grey--500: rgba(237, 237, 238, 1);
  --light-grey--600: rgba(212, 212, 213, 1);
  --light-grey--700: rgba(184, 184, 184, 1);
  --light-grey--800: rgba(150, 150, 151, 1);
  --light-grey--900: rgba(106, 106, 106, 1);
  --august--moon--50: rgba(253, 252, 251, 1);
  --august--moon--100: rgba(250, 249, 247, 1);
  --august--moon--200: rgba(245, 243, 239, 1);
  --august--moon--300: rgba(240, 237, 231, 1);
  --august--moon--400: rgba(234, 231, 223, 1);
  --august--moon--500: rgba(229, 225, 214, 1);
  --august--moon--600: rgba(205, 201, 191, 1);
  --august--moon--700: rgba(177, 174, 166, 1);
  --august--moon--800: rgba(145, 142, 135, 1);
  --august--moon--900: rgba(102, 101, 96, 1);
  --color-divider: rgba(0, 0, 0, 0.12);

  --color-brand1: var(--primary-500);
  --color-brand2: #03663d;
  --color-white: #ffffff;
  --color-offwhite1: #e9e9e9;
  --color-offwhite2: #e0e0e0;
  --color-offwhite3: #f2f3f4;
  --color-mediumgray: #c5c5c5;
  --color-gray1: #737373;
  --color-gray2: #d9d9d9;
  --color-gray3: #a5a5a5;
  --color-gray4: #656565;
  --color-gray5: #9e9e9e;
  --color-darkgray: #1f1f1f;
  --color-error: var(--basic-colors---danger);
  --color-bg-error: rgb(255 218 218);
  --color-register-subscription-price: rgba(255, 255, 255, 0.2);
  //Buttons
  --color-cancel-button: #e4e1e1;
  --color-cancel-button-hover: #dad7d7;
  --color-save-button: #bee8bb;
  --color-save-button-hover: #b0e3ac;
  --color-delete-button: #fee8e7;
  --color-delete-button-hover: #fdd6d4;

  //Progresbar
  --color-step2: #2eaf69;
  --color-step3: #1a9c6a;
  --color-step4: #077046;

  //highlights
  --color-highlight1: #7100c9;
  --color-highlight2: #0099c9;
  --color-highlight3: #68b1d7;

  //Trial progressbar steps
  --color-trial-step2: #32ad5f;
  --color-trial-step3: #23b07b;
  --color-trial-step4: #148f5f;
  --color-trial-step5: #076e44;

  //popup text colors
  --color-primary-popup-text: var(--light-theme---medium-text);

  //Hover
  --primary-hover: rgb(69, 170, 62, 0.1);

  //linar-gradient
  --color-gradient-card: linear-gradient(91.42deg, #45aa3e 0%, #2e882a 100%);

  //Statuses
  --color-closed: #e6fbd9;
  --color-closed-text: #0f5b1d;
  --color-trial-draft-phase-status: #23b07b;
  --color-draft-phase-status: #e5f1eb;

  //shadow
  --sidebar-shadow: 4px 0px 6px rgb(0 0 0 / 10%);
  --header-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  --card-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  --header-height: 88px;
  --project-header-height: 170px;

  --color-primary-10: 13 142 139;
  --color-primary-20: 0 30 66;

  --color-neutral-00: 255 255 255;
  --color-neutral-10: 241 241 241;
  --color-neutral-20: 222 222 222;
  --color-neutral-30: 163 163 163;
  --color-neutral-40: 51 51 51;
  --color-neutral-50: 40 40 40;
  --color-neutral-60: 39 39 39;
  --color-neutral-70: 28 28 28;
  --color-neutral-80: 28 28 18;
  --color-neutral-90: 0 0 0;

  --color-gray: 136 136 136;
  --color-red: 207 48 74;
  --color-green: 3 166 109;

  --color-subtitle-text: #4f5b67;

  --spacing: 40px;
  --spacing-q: calc(var(--spacing) * 0.25);
  --spacing-half: calc(var(--spacing) / 2);
  --spacing-tq: calc(var(--spacing) * 0.75);

  --color-icon-primary: #c7ccd1;
  --color-icon-primary-hover: #8d99a5;
  --color-icon-primary-active: #122330;

  --color-statistics-text: #8d99a5;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Sen, 'Helvetica Neue', sans-serif;
  --mdc-dialog-subhead-line-height: 28px;
  --mdc-dialog-subhead-size: 22px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-dialog-subhead-color: #212121;
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}


/*	Fonts
------------------------------------------------------- */
@font-face {
  font-family: 'Arimo';
  src: url('/fonts/Arimo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arimo';
  src: url('/fonts/Arimo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.section-title.little-section-title {
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
}
